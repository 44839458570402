import React from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import { Link } from "gatsby"
import loadable from "@loadable/component"

import "gatsby-theme-starberry-lomondgroup/src/components/BranchInfo/BranchInfo.scss";
import ImageModule from "gatsby-theme-starberry-lomondgroup/src/modules/image_module";

import { PageLinks } from "../../common/page-links";
import { defaultArea_Slug } from "../../common/constant";

const BranchInfo = ({office_data}) => {

    //console.log("office_data", office_data?.area_guides[0])

    const ImageRenderList = ({ item, imagename }) => {
        let processedImages = JSON.stringify({});
        if (item?.imagetransforms?.tile_image_Transforms) {
            processedImages = item?.imagetransforms?.tile_image_Transforms;
        }
        return (
            <ImageModule ImageSrc={item.banner_image} title={item.title} altText={item.title} imagetransforms={processedImages} ggfx_results={item?.ggfx_results} renderer="srcSet" imagename={imagename} strapi_id={item.id} />
        )
    }

    let latitude = office_data?.letting_info?.latitude || office_data?.latitude
    let longitude = office_data?.letting_info?.longitude || office_data?.longitude

    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${office_data?.latitude},${office_data?.longitude}`;

    const googleMapsUrl2 = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;


    return (
        <React.Fragment>
            <div className="branch-details-section">
                <Container>
                    <Row>
                        <Col md={6} lg={4}>
                            <div className="details-wrap">
                                <h2>{office_data?.office_type ? office_data?.office_type?.charAt(0).toUpperCase() + office_data?.office_type?.slice(1).toLowerCase().replace('_',' ') : ''}</h2>
                                
                                {office_data.mobile && <a href={`tel:${office_data.mobile}`} className="details-link"><span>{office_data.mobile}</span></a>}

                                {office_data.email && <a href={`mailto:${office_data.email}`} className="details-link active"><span>{office_data.email}</span></a>}
                                
                                {office_data.email && <Link to={`/${PageLinks.branch_finder}/${office_data.slug}/${PageLinks.branch_contact}/`} className="btn btn-outline">Contact {office_data?.office_type ? office_data?.office_type?.charAt(0).toUpperCase() + office_data?.office_type?.slice(1).toLowerCase().replace('_',' ') : ''} Team</Link>}
                                
                                <address className="details-link"><i className="icon-location"></i>{office_data.address}</address>

                                {office_data?.latitude && office_data?.longitude && <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer" className="details-link active"><span>Get Google Directions</span></a>}

                                {office_data?.office_crm_id && 
                                <div className="property-links">
                                    {office_data?.office_type === "sales" && 
                                        <>
                                            <Link to={`/${PageLinks.property_for_sale}/in-${defaultArea_Slug}/branch-${office_data?.office_crm_id.toLowerCase()}/`} className="details-link active"><span>Properties for sale</span></Link>
                                            <Link to={`/${PageLinks.property_for_sale}/in-${defaultArea_Slug}/branch-${office_data?.office_crm_id.toLowerCase()}/sale-agreed/`} className="details-link active"><span>Sold properties</span></Link>
                                        </>
                                    }

                                    {office_data?.office_type === "lettings" && 
                                        <>
                                            <Link to={`/${PageLinks.property_to_rent}/in-${defaultArea_Slug}/branch-${office_data?.office_crm_id.toLowerCase()}/`} className="details-link active"><span>Properties to let</span></Link>
                                            <Link to={`/${PageLinks.property_to_rent}/in-${defaultArea_Slug}/branch-${office_data?.office_crm_id.toLowerCase()}/let-agreed/`} className="details-link active"><span>Let properties</span></Link>
                                        </>
                                    }
                                </div>
                                }
                            </div>
                        </Col>
                        
                        {(office_data?.letting_info?.mobile || office_data?.letting_info?.email) &&
                        <Col md={6} lg={4}>
                            <div className="details-wrap">
                                <h2>Lettings</h2>
                                
                                {office_data?.letting_info.mobile && 
                                    <a href={`tel:${office_data?.letting_info.mobile}`} className="details-link"><span>{office_data?.letting_info.mobile}</span></a>
                                }

                                {office_data?.letting_info?.email && 
                                    <a href={`mailto:${office_data?.letting_info.email}`} className="details-link active"><span>{office_data?.letting_info?.email}</span></a>
                                }

                                {office_data?.letting_info?.email && 
                                    <Link to={`/${PageLinks.branch_finder}/${office_data.slug}/${PageLinks.branch_contact}/`} className="btn btn-outline" state={{to_email_id:office_data?.letting_info?.email}}>Contact Lettings Team</Link>
                                }
                                
                                <address className="details-link"><i className="icon-location"></i>{office_data?.letting_info?.address || office_data?.address}</address>

                                {(latitude && longitude) && <a href={googleMapsUrl2} target="_blank" rel="noopener noreferrer" className="details-link active"><span>Get Google Directions</span></a>}

                                {office_data?.office_crm_id && 
                                <div className="property-links">
                                    <Link to={`/${PageLinks.property_to_rent}/in-${defaultArea_Slug}/branch-${office_data?.office_crm_id.toLowerCase()}/`} className="details-link active"><span>Properties to let</span></Link>

                                    <Link to={`/${PageLinks.property_to_rent}/in-${defaultArea_Slug}/branch-${office_data?.office_crm_id.toLowerCase()}/let-agreed/`} className="details-link active"><span>Let properties</span></Link>
                                </div>
                                }
                            </div>
                        </Col>
                        }

                        <Col md={6} lg={4}>
                            {office_data?.opening_hours?.length > 0 && 
                                <div className="time-info">
                                    <h2>Opening Hours</h2>
                                    {office_data?.opening_hours?.map((item, index) => {
                                        return(
                                            <div className="timing">
                                                <span>{item.name}:</span> <span>{item.value}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                        </Col>

                        {(!office_data?.letting_info?.mobile  && !office_data?.letting_info?.email) && office_data?.area_guides?.length > 0 && 
                            <Col md={12} lg={4}>
                                <div className="branch-area-guide">
                                {office_data?.area_guides?.map((guide) => (
                                    <a href={`/${PageLinks.area_cover}/${guide?.slug}/`} key={guide?.slug}>
                                        <ImageRenderList item={guide} imagename={"area-guides.tile_image.branch_sidebar"} />
                                        <span className="transp_bg"></span>
                                        <div className="branch-area-content">
                                        <h3>
                                            {guide.title}
                                            <span>Area Guide</span>
                                        </h3>
                                        <a href={`/${PageLinks.area_cover}/${guide?.slug}/`}>Explore {guide.title}</a>
                                        </div>
                                    </a>
                                ))}
                                </div>
                            </Col>
                        }
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default BranchInfo;